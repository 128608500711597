import React from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from 'common/theme/cryptoModern';
import { ResetCSS } from 'common/assets/css/style';
import Sticky from 'react-stickynode';
import Navbar from 'containers/CryptoModern/Navbar';
import Banner from 'containers/CryptoModern/Banner';
import Features from 'containers/CryptoModern/FeatureSection';
import Footer from 'containers/CryptoModern/Footer'
import FaqSection from 'containers/CryptoModern/FaqSection';
import GlobalStyle, {
  CryptoWrapper,
  ContentWrapper,
} from 'containers/CryptoModern/cryptoModern.style';

import Seo from 'components/seo';
import BalancerAnalytics from '../containers/BalancerAnalytics';
import BalancerTools from '../containers/BalancerTools';
import TeamSection from '../containers/CryptoModern/TeamSection';
import AuraAnalytics from "../containers/AuraAnalytics";

const CryptoModern = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Seo title="DeFilytica" />

        <ResetCSS />
        <GlobalStyle />

        <CryptoWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-active">
            <Navbar />
          </Sticky>
          <ContentWrapper>
            <Banner />
            <Features />
            <BalancerAnalytics/>
            <AuraAnalytics/>
            <BalancerTools/>
            <FaqSection />
          </ContentWrapper>
          <TeamSection />
          <Footer />
        </CryptoWrapper>
      </>
    </ThemeProvider>
  );
};
export default CryptoModern;
