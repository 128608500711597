import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/GatsbyImage';
import SectionWrapper, { ContentWrapper } from './../BalancerAnalytics/balancer.style';
import {
    ButtonGroup,
} from './../CryptoModern/Banner/banner.style';


const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};

const AuraAnalytics = () => {
    const Data = useStaticQuery(graphql`
        query {
            illustration: file(
                relativePath: { eq: "image/cryptoModern/aura_analytics_mock.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FULL_WIDTH
                        placeholder: NONE
                        formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
        }
    `);
    return (
        <SectionWrapper id="products">
            <Container>
                <ContentWrapper>
                    <div className="content">
                        <Heading content="Analytics: Aura" />
                        <Text content="Based on our deep knowledge of the Balancer Protocol, we buil this customized analytics module for Aura - a yield-aggregator of Balancer. Its deep insights for liquidity provisioning and voting markets is highly valued by the community." />
                        <Fade up delay={300}>
                            <ButtonGroup>
                                <Button className="primary" title="Launch App" onClick={() => openInNewTab('https://aura.defilytica.com')}/>
                            </ButtonGroup>
                        </Fade>
                    </div>
                    <div className="image">
                        <Fade up>
                            <GatsbyImage
                                src={
                                    (Data.illustration !== null) | undefined
                                        ? Data.illustration.childImageSharp.gatsbyImageData
                                        : {}
                                }
                                alt="Balancer Tools"
                            />
                        </Fade>
                    </div>
                </ContentWrapper>
            </Container>
        </SectionWrapper>
    );
};

export default AuraAnalytics;
