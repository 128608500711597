import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/GatsbyImage';
import SectionWrapper, { ContentWrapper } from './../BalancerAnalytics/balancer.style';
import {
    ButtonGroup,
  } from './../CryptoModern/Banner/banner.style';


  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

const BalancerTools = () => {
  const Data = useStaticQuery(graphql`
    query {
      illustration: file(
        relativePath: { eq: "image/cryptoModern/defilytica_tools_mock.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <SectionWrapper id="products">
      <Container>
        <ContentWrapper>
          <div className="content">
            <Heading content="DAO Tooling Showcase: DeFilytica Tools" />
            <Text content="defilytica.tools is a tooling suite designed to interact with various protocols. The current focus is on Balancer and Aura tech. The tooling page allows you to browse Balancer v2 contracts, create DAO payloads and more" />
         <Fade up delay={300}>
            <ButtonGroup>
              <Button className="primary" title="Launch App" onClick={() => openInNewTab('https://defilytica.tools')}/>
            </ButtonGroup>
          </Fade>
          </div>
          <div className="image">
            <Fade up>
              <GatsbyImage
                src={
                  (Data.illustration !== null) | undefined
                    ? Data.illustration.childImageSharp.gatsbyImageData
                    : {}
                }
                alt="Balancer Tools"
              />
            </Fade>
          </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

export default BalancerTools;
