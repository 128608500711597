import React from 'react';
import Fade from 'react-reveal/Fade';
import { useStaticQuery, graphql } from 'gatsby';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Text from 'common/components/Text';
import Button from 'common/components/Button';
import Heading from 'common/components/Heading';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/GatsbyImage';
import BannerWrapper, {
  BannerContent,
  BannerImage,
  ButtonGroup,
} from './banner.style';

const Banner = () => {
  const Data = useStaticQuery(graphql`
    query {
      illustration: file(
        relativePath: { eq: "image/balancerAnalytics.png" }
      ) {
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: NONE
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `);
  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <Fade up delay={100}>
            <Heading
              as="h1"
              content="Welcome to the next level of DeFi analytics and consultancy services."
            />
          </Fade>
          <Fade up delay={200}>
            <Text
              content="We are a collective of DeFi natives focusing on building meaningful analytics and providing in-depth consultancy services 
              for the industry. Our experience in the Swiss IT Consulting and Banking space allows us to bring analytics to the next level. Swiss made"
            />
          </Fade>
          <Fade up delay={300}>
            <ButtonGroup>
            <AnchorLink href="#products" offset={84}>
              <Button className="primary" title="Explore Products" />
              </AnchorLink>
            </ButtonGroup>
          </Fade>
        </BannerContent>
        <BannerImage>
          <Fade in delay={100}>
            <GatsbyImage
              src={
                (Data.illustration !== null) | undefined
                  ? Data.illustration.childImageSharp.gatsbyImageData
                  : {}
              }
              alt="Banner"
            />
          </Fade>
        </BannerImage>
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
