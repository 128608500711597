import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Text from 'common/components/Text';
import Heading from 'common/components/Heading';
import Button from 'common/components/Button';
import Container from 'common/components/UI/Container';
import GatsbyImage from 'common/components/GatsbyImage';
import SectionWrapper, { ContentWrapper } from './balancer.style';
import { ButtonGroup } from './../CryptoModern/Banner/banner.style';

const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
};

const BalancerAnalytics = () => {
    const data = useStaticQuery(graphql`
        query {
            illustration: file(
                relativePath: { eq: "image/cryptoModern/balancer_analytics_2.png" }
            ) {
                childImageSharp {
                    gatsbyImageData(
                        layout: FULL_WIDTH
                        placeholder: NONE
                        formats: [AUTO, WEBP, AVIF]
                    )
                }
            }
        }
    `);

    return (
        <SectionWrapper id="products">
            <Container>
                <ContentWrapper>
                    <div className="content">
                        <Heading content="Analytics: Balancer" />
                        <Text
                            content="Balancer Analytics is an all-in-one analytics suite where we developed a tailor-made platform including protocol, pool and token metrics.
            Additionally, an in-depth financial dashboard and service-provider analytics board provides meaningful DAO insights."
                        />
                        <Fade up delay={300}>
                            <ButtonGroup>
                                <Button
                                    className="primary"
                                    title="Launch App"
                                    onClick={() => openInNewTab('https://balancer.defilytica.com')}
                                />
                            </ButtonGroup>
                        </Fade>
                    </div>
                    <div className="image">
                        <Fade up>
                            <GatsbyImage
                                image={data.illustration.childImageSharp.gatsbyImageData}
                                alt="Balancer Analytics"
                            />
                        </Fade>
                    </div>
                </ContentWrapper>
            </Container>
        </SectionWrapper>
    );
};

export default BalancerAnalytics;
